<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
                <FilterArea
                    style="width:100%;"
                    v-model:value="state.params.area"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:area="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"
                    v-model:distributor="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:provinsi="state.params.provinsi"
                    v-model:kabupaten="state.params.kabupaten"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterToko
                    style="width:100%;"
                    v-model:value="state.params.toko"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AMonthPicker
                    style="width:100%;"
                    v-model:value="state.params.periode"
                    :disabled-date="disabledDateMonthFilter"
                    :allow-clear="false"
                    placeholder="Pilih Periode"
                    format="MMMM YYYY"/>
            </ACol>
            <ACol v-if="hasRoles([ROLE_ADMIN_BK, ROLE_HELPDESK, ROLE_ASM, ROLE_SPC, ROLE_SPC_TARGET])"
                :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    style="width:100%;"
                    v-model:value="state.params.status"
                    show-search
                    allow-clear
                    placeholder="Pilih Status"
                    :options="state.statusLists"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="refreshFetch()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <AForm
            ref="formRefTable"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="actionChecked"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <div class="row justify-content-end mt-4">
                <div class="col-lg-6 col-md-12 mt-auto">
                    <div v-if="hasRoles([ROLE_TSO, ROLE_ASM, ROLE_SPC])">
                        <p>Approve: {{ state.action.approve }}</p>
                        <p>Reject: {{ state.action.reject }}</p>
                        <p>Delete: {{ state.action.delete }}</p>
                        <p>Sisa: {{ state.action.remaining }}</p>
                    </div>
                    <p>Sinkron terakhir: {{ state.action.lastTime }}</p>
                </div>
                <div class="col-lg-6 col-md-12 text-right mt-auto">
                    <ASpace>
                        <!-- Note: button Simpan digunakan sebagai submit -->
                        <ATooltip
                            v-if="hasRoles([ROLE_TSO, ROLE_ASM, ROLE_SPC])"
                            title="Simpan">
                            <AButton
                                type="primary"
                                html-type="submit"
                                :disabled="!state.action.button"
                                :loading="state.action.loading">
                                Simpan
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="state.isDisableUpload && hasRoles([ROLE_TSO, ROLE_ASM, ROLE_SPC])"
                            title="Upload Dokumen"
                            placement="left">
                            <AButton
                                type="primary"
                                @click="btnUpload">
                                <i class="fa fa-upload" aria-hidden="true"></i>
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            title="Download Excel"
                            placement="left">
                            <DownloadExcel
                                :url="state.endpoint.current"
                                :params="queryParams()"
                                @errors="errorMessage"/>
                        </ATooltip>
                        <ATooltip
                            title="Download Excel Detail Transaksi"
                            placement="left">
                            <AButton
                                type="primary"
                                @click="btnDownloadExcelRaw"
                                :loading="state.isDownloadingRaw">
                                <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.isDownloadingRaw"></i>
                                <span v-else>Downloading ...</span>
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </div>
            </div>
    
            <!-- list table -->
            <div class="table-responsive text-nowrap mt-4">
                <MdTable
                    :columns="state.columns"
                    :data-source="state.data"
                    size="small"
                    :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                    :pagination="{
                        showSizeChanger: true,
                        pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                        showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                        total: state.meta.total,
                        pageSize: state.meta.per_page,
                        current: state.meta.page,
                    }"
                    @change="handleTableChange"
                    :loading="state.isFetching">
    
                    <template #no="{ index }">
                        <span>
                            {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                        </span>
                    </template>
    
                    <template
                        #action="{ index, text }">
                        <ARadioGroup
                            v-model:value="text.action_status"
                            @input="listenActionButton"
                            :key="index">
                            <ASpace>
                                <!--
                                    action_button_request digunakan untuk disable button ketika
                                    TSO reject item ke ASM -->
                                <ATooltip title="Approve">
                                    <ARadioButton
                                        class="approve"
                                        shape="circle"
                                        :id="text.suggestion_id"
                                        :disabled="!text.action_button_request"
                                        value="approve">
                                        <i class="fa fa-check" aria-hidden="true"/>
                                    </ARadioButton>
                                </ATooltip>
                                <ATooltip title="Reject">
                                    <ARadioButton
                                        class="reject"
                                        shape="circle"
                                        :id="text.suggestion_id"
                                        :disabled="!text.action_button_request"
                                        value="reject">
                                        <i class="fa fa-times" aria-hidden="true"/>
                                    </ARadioButton>
                                </ATooltip>
                                <ATooltip
                                    v-if="text.role_action_delete"
                                    title="Delete">
                                    <ARadioButton
                                        class="delete"
                                        shape="circle"
                                        :id="text.suggestion_id"
                                        value="delete"
                                        :disabled="!text.action_button_request">
                                        <i class="fa fa-trash" aria-hidden="true"/>
                                    </ARadioButton>
                                </ATooltip>
                            </ASpace>
                        </ARadioGroup>
                    </template>
    
                    <template
                        #upload="{ index, text }">
                        <AFormItem
                            :name="['files', index, 'file']">
                            <AInput
                                type="file"
                                v-model:value="text.file"
                                accept="image/png, image/gif, image/jpeg, image/webp, application/pdf"
                                :disabled="!text.isUpload"
                                @change="(file) => listenBeforeUpload(file, index)"/>
                        </AFormItem>

                        <AImage
                            v-if="text.file_type == 'image'"
                            :src="text.image_url"
                            style="width:100%"/>
                        <iframe
                            v-if="text.file_type == 'iframe'"
                            :src="text.image_url"
                            width="100%"/>
                    </template>
    
                    <template
                        #note="{ index, text }">
                        <ATextarea
                            v-model:value="text.action_note"
                            :key="index"
                            placeholder="note"
                            allow-clear
                            :id="text.suggestion_id"
                            :disabled="!text.action_button_request"
                            @input="listenActionNote"/>
                    </template>
                </MdTable>
            </div>

        </AForm>

        <!-- upload -->
        <Upload
            v-if="visibleUploadModal"
            v-model:visible="visibleUploadModal"
            @success="fetchDataList"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, h } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import Form from 'vform'
import Upload from './Upload'
import _ from 'lodash'
import {
    ROLE_ADMIN_BK,
    ROLE_ASM,
    ROLE_TSO,
    ROLE_SPC,
    ROLE_HELPDESK,
    ROLE_SPC_TARGET,
    hasRoles,
} from '@/helpers'
import moment from 'moment'
import includes from 'lodash/includes'
import sumBy from 'lodash/sumBy'
import store from 'store'
import {
    updateUrlWithQuery,
    extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        FilterProvince,
        FilterKabupatenKota,
        FilterDistributor,
        FilterRegional,
        FilterArea,
        FilterToko,
        DownloadExcel,
        Upload,
    },
    setup() {
        const errorMessage = ref()

        const formRef = ref();
        const formRefTable = ref();

        const form = reactive(new Form({
            files: [],
        }))

        const state = reactive({
            columnBackup: [],
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
                {
                    title: 'Upload',
                    width: 200,
                    slots: { customRender: 'upload' },
                },
                {
                    title: 'Note',
                    width: 200,
                    slots: { customRender: 'note' },
                },
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Region',
                    dataIndex: 'regional',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Alamat',
                    dataIndex: 'address',
                },
                {
                    title: 'Area',
                    dataIndex: 'area',
                },
                {
                    title: 'Kota',
                    dataIndex: 'city',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'province',
                },

                {
                    title: 'SSM',
                    dataIndex: 'user_ssm',
                },
                {
                    title: 'ASM',
                    dataIndex: 'user_sm',
                },
                {
                    title: 'TSO',
                    dataIndex: 'user_am',
                },
                {
                    title: 'Distributor Mapping',
                    dataIndex: 'distributor_mapping',
                },
                {
                    title: 'Average Volume Toko (2 Bulan)',
                    dataIndex: 'avg_volume',
                },
                {
                    title: 'Status',
                    dataIndex: 'status_approval',
                },
                {
                    title: `Catatan ${(hasRoles([ROLE_ASM]) ? 'TSO' : 'ASM')}`,
                    dataIndex: 'rejection_note',
                },
                // {
                //     title: '',
                //     slots: { customRender: 'action' },
                // },
            ],
            endpoint: {
                current: '/api/report-lelang/suggestion',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            isDownloadingRaw: false,
            params: extractQueryParams({
                q: '',
                region: [],
                provinsi: [],
                distributor: [],
                area: [],
                kabupaten: [],
                toko: [],
                status: null,
                periode: moment(new Date()).startOf('month').format('YYYY-MM'),
                page: 1,
                "per-page": 10,
            }),
            selectedRows: [],
            selectedRowKeys: [],
            formStatus: {
                reject_note: '',
            },
            statusLists: [
                {
                    label: "Awaiting/suggest lelang",
                    value: 1,
                },
                {
                    label: "Approve level 1 (tso)",
                    value: 2,
                },
                {
                    label: "Reject level 1 (tso)",
                    value: 3,
                },
                {
                    label: "Approve level 2 (asm)",
                    value: 4,
                },
                {
                    label: "Reject level 2 (asm)",
                    value: 5,
                },
            ],
            action: {
                loading: false,
                button: false,
                files: [], // storage files upload
                storages: [],
                approve: 0,
                reject: 0,
                delete: 0,
                remaining: 0,
                lastTime: '',
                disableAction: false,
            },
            isDisableUpload: true,
            rules: {
                files: [],
            },
            rule_references: {
                file: [
                    {
                        required: false,
                        message: "File tidak boleh kosong!",
                    },
                ],
            },
        })

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            refreshFetch()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.periode) {
                params.periode = moment(state.params.periode).format('YYYY-MM')
            }

            return updateUrlWithQuery(params)
        }

        const refreshFetch = () => {
            fetchDataList()
            fetchSynchron()
            fetchClosePeriode()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item, i) => {
                        item.key = i
                        item['progress_download'] = false
                        // only role am
                        item.role_action_delete = hasRoles([ROLE_TSO])
                        // validation upload by delete action
                        item.isUpload = false

                        if (i == 0) {
                            state.columns = state.columnBackup
                            state.action.disableAction = item.action_button || moment(item.periode).isSame(new Date(), 'month')
                            validateRole()
                        }

                        // validate file submit
                        state.rules.files.push(Object.assign({}, {
                            ...state.rule_references,
                            suggestion_id: item.suggestion_id,
                        }))
                        form.files.push(Object.assign({}, {file: null}))
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount

                    // TODO: comment test
                    if (state.meta.total) {
                        loadActionStorage()
                    } else {
                        resetActionStorage()
                    }
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // fetch synchron
        const fetchSynchron = () => {
            apiClient
                .get(state.endpoint.current, {
                    params: {
                        ...queryParams(),
                        _data: 'synchron',
                    },
                })
                .then(({ data }) => {
                    state.action.lastTime = data.last_synchron
                })
        }

        // fetch close periode
        const fetchClosePeriode = () => {
            apiClient
                .get('/api/synchron-open/cek', {
                    params: {
                        periode: moment(state.params.periode).format('YYYY-MM'),
                    },
                })
                .then(({ data }) => {
                    state.isDisableUpload = data.openClosePriode
                })
        }

        const postActionStatus = (payloads) => {
            state.action.loading = true
            apiClient.post(`/api/report-lelang/approval`, payloads)
                .then(({ data }) => {
                    refreshFetch()
                    message.success(data.message.approve)
                    message.success(data.message.reject)
                    message.success(data.message.delete)

                    // reset localstorage
                    resetActionStorage()
                })
                .catch(e => message.error('Gagal mengirim status!'))
                .finally(() => {
                    state.action.loading = false
                })
        }

        // download export excel raw
        const btnDownloadExcelRaw = () => {
            state.isDownloadingRaw = true
            apiClient
                .get(state.endpoint.current, {
                    params: {
                        ...queryParams(),
                        _export: 'xls-raw',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `Laporan-Suggestion-Toko-Lelang_${moment().format('DDMMYY_HHmm')}_Raw.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.isDownloadingRaw = false
                })
        }

        const actionChecked = () => {

            Modal.confirm({
                title: 'Perhatian:',
                content: h('div', {}, [
                    h('p', 'Tindakan Approve/Reject yang akan Anda lakukan akan langsung terproses. Apabila ada kesalahan, maka toko-toko terkait tidak akan muncul kembali di Menu Suggestion Toko Lelang hingga periode bulan berikutnya.'),
                    h('br'),
                    h('strong', 'Pastikan data sudah benar. Apakah Anda yakin untuk melanjutkan?'),
                ]),
                onOk() {
                    const payloads = new FormData()

                    for (let [index, item] of Object.entries(state.action.storages)) {
                        if ((item.status == 'reject' || item.status == 'delete') && (item.note == undefined || item.note == '')) {
                            message.info('catatan masih ada yang kosong di status reject atau delete')
                            return
                        }

                        payloads.append(`${item.status}[${index}][suggestion_id]`, item.id)
                        payloads.append(`${item.status}[${index}][note]`, item.note || '')
                        payloads.append(`${item.status}[${index}][status]`, item.status_approval_code)

                        if (item.status == 'delete') {
                            let firstFile = form.files.find(v => v.id == item.id)
                            if (firstFile) {
                                payloads.append(`${item.status}[${index}][image]`, firstFile.file)
                            }
                        }
                    }

                    postActionStatus(payloads)
                },
            })
        }

        const listenActionButton = (event) => {
            let id = _.toInteger(event.target.getAttribute('id'))
            let status = event.target.value

            let find = state.action.storages.find(item => item.id == id)
            let first = state.data.find(item => item.suggestion_id == id)

            if (find) {
                find.status = status
                find.status_approval_code = first.status_approval_code
            } else {
                state.action.storages.push({
                    id: id,
                    status: status,
                    status_approval_code: first.status_approval_code,
                })
            }

            let isDelete = status == 'delete'

            // change validation file
            ChangeRuleValidation(id, isDelete)

            // jika selain delete maka file data akan di reset
            if (!isDelete) {
                first.file = null
                first.file_type = null
                first.image_url = null
                first.image = null

                // reset form agar file field null, agar inputan file bisa ke change
                _.forEach(form.files, item => {
                    if (item.id === id) {
                        item.file = null
                    }
                })
            }

            // for enabled upload input
            first.isUpload = isDelete

            state.action.approve = sumBy(state.action.storages, (item) => +(item.status == 'approve'))
            state.action.reject = sumBy(state.action.storages, (item) => +(item.status == 'reject'))
            state.action.delete = sumBy(state.action.storages, (item) => +(item.status == 'delete'))

            state.action.button = state.action.storages.length > 0
            state.action.remaining = state.meta.total - state.action.storages.length

            localStorage.setItem(_prefixUser("action_data"), JSON.stringify(state.action.storages))
            localStorage.setItem(_prefixUser("action_approve"), state.action.approve)
            localStorage.setItem(_prefixUser("action_reject"), state.action.reject)
            localStorage.setItem(_prefixUser("action_delete"), state.action.delete)
        }

        const listenBeforeUpload = (e, index) => {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return

            let first = state.data.find((item, i) => i == index)
            let url = URL.createObjectURL(files[0])

            if (first && url) {
                first.file_type = files[0].type === 'application/pdf' ? 'iframe' : 'image'
                first.image_url = url
                first.image = files[0]
                form.files[index].file = files[0]
                form.files[index].id = first.suggestion_id
            }
        }

        const listenActionNote = _.debounce((event) => {
            let id = event.target.getAttribute('id')

            let find = state.action.storages.find(item => item.id == id)
            if (find) {
                find.note = event.target.value
            } else {
                state.action.storages.push({
                    id: id,
                    note: event.target.value,
                })
            }

            localStorage.setItem(_prefixUser("action_data"), JSON.stringify(state.action.storages))
        }, 500)

        const ChangeRuleValidation = (id, status) => {
            // Buat payload baru yang mencakup hanya data yang perlu diubah
            const updatedRules = state.rules.files.map(rule => {
                if (rule.suggestion_id === id) {
                    return {
                        ...rule,
                        file: rule.file.map(fileItem => ({
                            ...fileItem,
                            required: status,
                        })),
                    }
                }
                return rule // Biarkan data lain tidak berubah
            })

            // Replace semua data dengan payload baru
            state.rules.files = updatedRules
        }

        const loadActionStorage = () => {
            state.data.forEach(item => {
                let find = state.action.storages.find(i => i.id == item.suggestion_id)
                if (find) {
                    item.action_status = find.status
                    item.action_note = find.note
                    
                    // true: aktifkan button upload
                    const status = (find.status == 'delete') 
                    if (status) {
                        item.isUpload = status

                        // change validation file
                        ChangeRuleValidation(item.suggestion_id, status)
                    }
                }
            })

            if (localStorage.getItem(_prefixUser('action_approve'))) {
                state.action.approve = localStorage.getItem(_prefixUser('action_approve'))
            }

            if (localStorage.getItem(_prefixUser('action_reject'))) {
                state.action.reject = localStorage.getItem(_prefixUser('action_reject'))
            }

            if (localStorage.getItem(_prefixUser('action_delete'))) {
                state.action.delete = localStorage.getItem(_prefixUser('action_delete'))
            }

            state.action.button = state.action.storages.length > 0
            state.action.remaining = state.meta.total - state.action.storages.length
        }

        const resetActionStorage = () => {
            state.action = {
                button: false,
                storages: [],
                files: [],
                approve: 0,
                reject: 0,
                delete: 0,
                remaining: 0,
            }

            localStorage.removeItem(_prefixUser('action_data'))
            localStorage.removeItem(_prefixUser('action_approve'))
            localStorage.removeItem(_prefixUser('action_reject'))
            localStorage.removeItem(_prefixUser('action_delete'))
        }

        const _prefixUser = (name) => {
            const user = store.get('profile')
            return `${user.id}_${name}`
        }

        const disabledDateMonthFilter = (current) => {
            return current && current > moment()
        };

        const validateRole = () => {
            // validation list status for a ASM role
            if (hasRoles([ROLE_ASM, ROLE_SPC])) {
                state.statusLists = state.statusLists.filter(item => {
                    if (includes([2, 3], item.value)) {
                        return item
                    }
                })
            }

            if (hasRoles([ROLE_TSO])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['rejection_note', 'status_approval'], item.dataIndex)) {
                        return item
                    }
                })
            }

            // state.action.disableAction digunakan untuk menghilangkan action kolom saat bulan selanjutnya
            // Note: jika menggunakan ket dataIndex maka kolom action akan error (Cannot read properties of undefined (reading 'action_status'))
            if (hasRoles([ROLE_ADMIN_BK, ROLE_HELPDESK]) || !state.action.disableAction) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['Action', 'Note'], item.title)) {
                        return item
                    }
                })
            }

            if (hasRoles([ROLE_ADMIN_BK, ROLE_HELPDESK])) {
                state.statusLists = state.statusLists.filter(item => {
                    if (includes([1, 2, 3], item.value)) {
                        return item
                    }
                })
            }

            // upload hanya di lihat di role TSO saja
            if (!hasRoles([ROLE_TSO])) {
                state.columns = state.columns.filter(item => {
                    return item.title !== 'Upload'
                })
            }
        }

        // handle upload
        const visibleUploadModal = ref(false);

        const btnUpload = () => {
            visibleUploadModal.value = true
        }

        // handle vue
        onMounted(() => {
            state.columnBackup = state.columns

            if (localStorage.getItem(_prefixUser('action_data'))) {
                state.action.storages = JSON.parse(localStorage.getItem(_prefixUser('action_data')))
            }

            refreshFetch()

            // validate role
            validateRole()
        })

        return {
            state,
            handleTableChange,
            onSelectChange,
            btnDownloadExcelRaw,
            errorMessage,
            queryParams,
            actionChecked,
            listenActionNote,
            listenActionButton,
            refreshFetch,
            disabledDateMonthFilter,
            visibleUploadModal,
            btnUpload,
            listenBeforeUpload,
            formRef,
            formRefTable,
            form,
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_HELPDESK,
            ROLE_ASM,
            ROLE_TSO,
            ROLE_SPC,
            ROLE_SPC_TARGET,
        }
    },
})
</script>

<style scoped lang="scss">
.approve,
.reject,
.delete {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;

    &.ant-radio-button-wrapper-checked {
        color: white !important;
    }
}
.approve {
    padding: 0px 8px !important;
    color: #009688;
    
    &.ant-radio-button-wrapper-checked {
        background-color: #009688 !important;
        border-color: #009688 !important;
    }
}
.reject {
    padding: 0px 10px !important;
    color: #f44336;
    
    &.ant-radio-button-wrapper-checked {
        background-color: #f44336 !important;
        border-color: #f44336 !important;
    }
}
.delete {
    padding: 0px 10px !important;
    color: #9e9e9e;
    
    &.ant-radio-button-wrapper-checked {
        background-color: #9e9e9e !important;
        border-color: #9e9e9e !important;
    }
}

</style>